@font-face {
  font-family: 'Proxima Nova';
  src: url('Proxima Nova Alt Regular-webfont.eot');
  src: url('Proxima Nova Alt Regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('Proxima Nova Alt Regular-webfont.woff') format('woff'),
    url('Proxima Nova Alt Regular-webfont.ttf') format('truetype'),
    url('Proxima Nova Alt Regular-webfont.svg#proxima_nova_altregular')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('lato-regular.eot');
  src: url('lato-regular.eot?#iefix') format('embedded-opentype'),
    url('lato-regular.woff2') format('woff2'),
    url('lato-regular.woff') format('woff'),
    url('lato-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
