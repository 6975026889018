@import "./fonts/font.scss";

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  user-select: none;
}

*:focus,
textarea:focus,
input:focus,
button:focus {
  outline: none;
}

body {
  padding: 0;
  margin: 0;
	font-family: Lato, sans-serif;
}
